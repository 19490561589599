


















































import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Location from '@/models/Location';
import {LCircle, LMap, LMarker, LPolyline, LTileLayer, LTooltip} from 'vue2-leaflet';
import {icon} from 'leaflet';
import mapIcon from '@/assets/images/map/map-marker-location.png';
import Customer from '@/models/Customer';
import {locationStoreActions, locationStoreGetter, locationStoreMutations} from '@/stores/location.store';
import {customerStoreGetter} from '@/stores/customer.store';
import {GeometryTypes} from '@/misc/Enums/Constants';
const CustomerStore = namespace('customer');
const LocationStore = namespace('location');

@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LTooltip,
  },
})
export default class LocationManageSpecificDataComponent extends Vue {

  public zoomConfigPolyline: any = {
    lat: 0,
    lng: 0,
  };
  @LocationStore.Mutation(locationStoreMutations.STORE_LOCATION)
  public storeLocationMutation!: (location: Location | undefined) => void;
  /**
   * The Map cannot be shown correctly
   */
  public mapError: boolean = false;
  private MAP_ICON_SCALE_QUOTIENT: number = 2;
  private MAP_RADIUS_DEFAULT_ZOOM: number = 14;
  /**
   * Map config values
   */
  public mapConfig: any = {
    url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
    zoom: this.MAP_RADIUS_DEFAULT_ZOOM,
    center: [0, 0],
    polyline: [[0, 0]],
    markerCoords: [0, 0],
    color: 'red',
    diversionColor: 'green',
    icon: icon({
      iconUrl: mapIcon,
      // 52,90 are the original dimension
      iconSize: [75 / this.MAP_ICON_SCALE_QUOTIENT, 75 / this.MAP_ICON_SCALE_QUOTIENT],
      iconAnchor: [37.5 / this.MAP_ICON_SCALE_QUOTIENT, 75 / this.MAP_ICON_SCALE_QUOTIENT],
    }),
  };
  // variable for holding our Interval, for retrieving the location from the api
  private geoInterval: any = undefined;
  // Time to poll in ms
  private GEOCODE_POLL_TIME_IN_MS: number = 1000;
  // Values for the status selection
  private objectStatus: Array<{ status: string, value: boolean }> = [];
  private isChanging: boolean = false;
  @LocationStore.Action(locationStoreActions.LOAD_LOCATION_ACTION)
  private loadLocationAction!: (payload: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @LocationStore.Action(locationStoreActions.EDIT_LOCATION_ACTION)
  private editLocationAction!: (payload: { location: Location, shouldBeStored: boolean }) => Promise<Location>;
  @CustomerStore.Getter(customerStoreGetter.CUSTOMER)
  private customer!: Customer;

  @LocationStore.Getter(locationStoreGetter.LOCATION)
  private _location!: Location;

  get location(): Location {
    return this._location;
  }

  public destroyed() {
    // On Destroy, clear the geo interval if it was set
    if (this.geoInterval) {
      clearInterval(this.geoInterval);
    }
  }

  @Watch('location', {immediate: true})
  public onLocationChange() {
    // We switched locations, clear our current interval
    // Clear Intervals, so it doesnt suck up memory and the exectuion stops
    if (this.geoInterval) {
      clearInterval(this.geoInterval);
    }

    if (this.location && this.location.address!.geoPosition?.positions.length) {
      // Check for Geo Positions inside the address object
      let geoPosition = [this.location.address!.geoPosition?.positions[0].lat, this.location.address!.geoPosition?.positions[0].lng];
      const geoPending = this.location.address!.geocodePending;
      const geoType = this._location.address?.geoPosition?.type;
      const geoPositionLine = this._location.address?.geoPosition?.positions;
      const geoPositionDiversion = this._location.diversions.map((item) => item.geoPosition?.positions);
      if (geoPosition) {
        if (geoType === GeometryTypes.LINESTRING) {
          this.mapConfig.polyline = geoPositionLine;
          geoPosition = this.zoomConfigPolyline;
          let sumLat = 0;
          let sumLng = 0;
          const length = this.location.address?.geoPosition?.positions.length!;
          this.zoomConfigPolyline.lat = this._location.address?.geoPosition?.positions.map((item) => item.lat)
              .forEach((element) => sumLat += element);
          this.zoomConfigPolyline.lng = this._location.address?.geoPosition?.positions.map((item) => item.lng)
              .forEach((element) => sumLng += element);
          this.zoomConfigPolyline.lat = sumLat / length;
          this.zoomConfigPolyline.lng = sumLng / length;
        }
        if (geoType === GeometryTypes.POINT) {
          this.mapConfig.markerCoords = geoPosition;
        }
        this.mapConfig.center = geoPosition;
        this.mapError = false;
      } else {
        this.mapError = true;
        if (geoPending) {
          // Start Interval to ask the api if we already have an geoposition
          this.geoInterval = setInterval(() => this.onGeoPositionAvailable(this.location),
              this.GEOCODE_POLL_TIME_IN_MS);
        }
      }
    }
  }

  public async updateLocation() {
    this.isChanging = true;
    const locationCopy = Location.parseFromObject(this.location);
    try {
      await this.editLocationAction({
        location: locationCopy,
        shouldBeStored: false,
      });
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.SUCCESS');
    } catch (e) {
      this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.ERROR');
    } finally {
      this.isChanging = false;
    }
  }

  public created() {
    this.objectStatus = [
      {status: this.$t('GENERAL.ACTIVE').toString(), value: true},
      {status: this.$t('GENERAL.INACTIVE').toString(), value: false},
    ];
  }

  private async onGeoPositionAvailable(currentLocation: Location) {
    // Get updated location from API, dont store for now. so no update of the page will be triggered
    const loc: Location = await this.loadLocationAction({locationId: currentLocation.id!, shouldBeStored: false});

    // Check if our geocode is not pending anymore. && loc.address?.geoPosition
    if (!loc.address!.geocodePending) {
      // No Geo Position available set map to error
      if (!loc.address?.geoPosition) {
        this.mapError = true;
      }

      // Turn of any map errors
      this.mapError = false;

      // Save this location inside our CustomerStore
      this.storeLocationMutation(loc);
      // Remove intervall for current item
      clearInterval(this.geoInterval);
    }
  }

}
