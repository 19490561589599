import { render, staticRenderFns } from "./LocationManageSpecificData.component.vue?vue&type=template&id=527a2da5&scoped=true&"
import script from "./LocationManageSpecificData.component.vue?vue&type=script&lang=ts&"
export * from "./LocationManageSpecificData.component.vue?vue&type=script&lang=ts&"
import style0 from "./LocationManageSpecificData.component.vue?vue&type=style&index=0&id=527a2da5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "527a2da5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VIcon,VProgressCircular})
